const quoteFeed = {};

quoteFeed.maxTicks = 20000;
quoteFeed.url = `${window.md?.urlConfig?.apiServiceBaseUrl || ''}/search/v1/retail/hypo10k/`;

import benchmarks from './benchmarks.json';

quoteFeed.buildUrl = function (symbol, startDate, endDate, params, cb) {
	var url = this.url;
	if (benchmarks.findIndex(e => e.id === symbol) === -1) {
		url += 'investments';
	} else {
		url += 'benchmarks';
	}
	var params;
	try { 
		params = JSON.parse(sessionStorage.getItem("spanParams")); 
	} catch(e) { 
		params = null;
	}
	var period = params ? (params.base === 'year' ? `${params.multiplier}YR` : 'YTD') : '1YR';

	url += `?id=${symbol}&period=${period}`;
	return {url, period};
}

quoteFeed.formatChartData = function (symbol, response, cb) {
	var data = JSON.parse(response);
	var newQuotes = [];

	data[symbol].performance.valueByDate.map((value, index) => {
		var newQuote = {};
		if (index === 0) {
			newQuote.AsOfDate = new Date(data[symbol].asOfDate);
		}
		newQuote.DT = new Date(value.date);
		newQuote.Close = parseFloat(value.amount);
		newQuotes.push(newQuote);
	});
	return newQuotes;
};

quoteFeed.postAjax = function (url, cb) {
	var server = new XMLHttpRequest();
	url += (url.indexOf("?") === -1 ? "?" : "&") + new Date().getTime();
	server.open("GET", url);
	server.onload = function () {
		cb(this.status, this.responseText);
	};
	server.onerror = function () {
		cb(500);
	};
	server.send();
};

quoteFeed.fetchInitialData = function (symbol, startDate, endDate, params, cb) {
	var self = this;
	var urlObject = self.buildUrl(symbol, startDate, endDate, params, cb);
	self.postAjax(urlObject.url, function (status, response) {
		// process the HTTP response from the datafeed
		if (status == 200) {
			// if successful response from datafeed
			var newQuotes = self.formatChartData(symbol, response);
			cb({ quotes: newQuotes, moreAvailable: false, upToDate: true });
		} else {
			// else error response from datafeed
			cb({ error: status });
		}
		if (self.callback) {
			self.callback(symbol, urlObject, response);
		}
		return;
	});
};

quoteFeed.fetchUpdateData = function (symbol, startDate, params, cb) {
	var self = this;
	var urlObject = self.buildUrl(symbol, startDate, endDate, params, cb);
	self.postAjax(urlObject.url, function (status, response) {
		// process the HTTP response from the datafeed
		if (status == 200) {
			// if successful response from datafeed
			var newQuotes = self.formatChartData(symbol, response);
			cb({ quotes: newQuotes });
		} else {
			// else error response from datafeed
			cb({ error: status });
		}
		if (self.callback) {
			self.callback(symbol, urlObject, response);
		}
		return;
	});
};

quoteFeed.fetchPaginationData = function (symbol, startDate, endDate, params, cb) {
	var self = this;
	var urlObject = self.buildUrl(symbol, startDate, endDate, params, cb);
	self.postAjax(urlObject.url, function (status, response) {
		// process the HTTP response from the datafeed
		if (status == 200) {
			// if successful response from datafeed
			var newQuotes = self.formatChartData(symbol, response);
			cb({ quotes: newQuotes });
		} else if (status == 404) {
			// use whatever flags or status codes you feed returns when there is no more data.
			cb({ quotes: [], moreAvailable: false });
		} else {
			// else error response from datafeed
			cb({ error: status });
		}
		if (self.callback) {
			self.callback(symbol, urlObject, response);
		}
		return;
	});
};

export default quoteFeed;
